import React from "react";
import { Card } from "@/components/Card";
import { Icon } from "@/components/Icon";
import { Spinner } from "@/components/Spinner";
import { useFela } from "react-fela";
import { useTheme } from "@/hooks/useTheme";
import useTranslation from "next-translate/useTranslation";

interface IProps {
  previewUrl?: string;
}

export const PreviewImage: React.FC<IProps> = ({ previewUrl }) => {
  const { t } = useTranslation("converter");
  const { css } = useFela();
  const { bp, colors } = useTheme();

  const [imageLoaded, setImageLoaded] = React.useState(false);

  return (
    <Card
      customStyle={{
        boxShadow: "0 2px 22px 0 rgba(0, 0, 0, 0.08)",
        [bp.FROM_TABLET]: {
          padding: "18px 40px",
        },
      }}
    >
      {!imageLoaded && !previewUrl ? (
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "74px 0 22px",
          })}
        >
          <Icon iconType="NO-PREVIEW-ICON" />
          <p
            className={css({
              marginTop: 54,
              fontSize: 18,
              lineHeight: "25px",
              color: colors.DARK_GREY,
            })}
          >
            {t("preview.notAvailable")}
          </p>
        </div>
      ) : !imageLoaded ? (
        <div className={css({ height: 200 })}>
          <Spinner />
        </div>
      ) : null}

      <img
        src={previewUrl}
        onLoad={() => setImageLoaded(true)}
        alt="Converted file preview"
        className={css({
          objectFit: "contain",
          maxWidth: "100%",
          height: 200,
          width: "auto",
          display: imageLoaded ? "block" : "none",
        })}
      />
    </Card>
  );
};
