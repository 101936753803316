import * as Sentry from "@sentry/node";

import { IRawFileRecord } from "@/typings/file";
import { handleError, handleHttpError } from "@/errors";
import { BACKEND_ENDPOINTS } from "@/constants/backend";
import { IConvertStatus } from "@/containers/Files/types";
import { IExtension } from "@/containers/Converter/types";

export interface IFile {
  name: string;
  size: number;
  lastModified: number;
  url: string;
  status: IConvertStatus;
}

type IFiles = {
  xd: {
    preview: IFile | null;
    full: IFile | null;
  };
  sketch: {
    preview: IFile | null;
    full: IFile | null;
  };
  figma: {
    preview: IFile | null;
    full: IFile | null;
  };
  psd: {
    preview: IFile | null;
    full: IFile | null;
  };
  ai: {
    preview: IFile | null;
    full: IFile | null;
  };
  pdf: {
    preview: IFile | null;
    full: IFile | null;
  };
  googleslides: {
    preview: IFile | null;
    full: IFile | null;
  };
  eps: {
    preview: IFile | null;
    full: IFile | null;
  };
  canva: {
    preview: IFile | null;
    full: IFile | null;
  };
  indd: {
    preview: IFile | null;
    full: IFile | null;
  };
  powerpoint: {
    preview: IFile | null;
    full: IFile | null;
  };
  webflow: {
    preview: IFile | null;
    full: IFile | null;
  };
  miro: {
    preview: IFile | null;
    full: IFile | null;
  };
  mural: {
    preview: IFile | null;
    full: IFile | null;
  };
  lucidchart: {
    preview: IFile | null;
    full: IFile | null;
  };
  figjam: {
    preview: IFile | null;
    full: IFile | null;
  };
  bubble: {
    preview: IFile | null;
    full: IFile | null;
  };
  website: {
    preview: IFile | null;
    full: IFile | null;
  };
  axure: {
    preview: IFile | null;
    full: IFile | null;
  };
  express: {
    preview: IFile | null;
    full: IFile | null;
  };
  aftereffects: {
    preview: IFile | null;
    full: IFile | null;
  };
  jpg: {
    preview: IFile | null;
    full: IFile | null;
  };
  freehand: {
    preview: IFile | null;
    full: IFile | null;
  };
  keynote: {
    preview: IFile | null;
    full: IFile | null;
  };
  html: {
    preview: IFile | null;
    full: IFile | null;
  };
  wordpress: {
    preview: IFile | null;
    full: IFile | null;
  };
  visio: {
    preview: IFile | null;
    full: IFile | null;
  };
};

export type IResponse = {
  previewUrl: string;
  hostedPageId: string;
  artboardsCount: number;
  hasMissingLinkedElements: boolean;
} & IFiles;

const formats = [
  "xd",
  "sketch",
  "figma",
  "figjam",
  "psd",
  "ai",
  "pdf",
  "googleslides",
  "eps",
  "canva",
  "indd",
  "powerpoint",
  "miro",
  "mural",
  "figjam",
  "express",
  "aftereffects",
  "jpg",
  "freehand",
  "html",
  "wordpress",
  "visio",
];

export const fileTypesWithoutExtensions: Record<string, IExtension> = {
  figjam: ".jam",
};

export const fetchFileDetails = (fileName: string): Promise<IResponse> => {
  const url = `${BACKEND_ENDPOINTS.fileDetails}?fileName=${encodeURIComponent(
    fileName,
  )}`;

  return fetch(url, { method: "GET" })
    .then(handleHttpError)
    .then(r => r.json() as Promise<IRawFileRecord>)
    .then(handleError)
    .then(res => {
      // Transform new-style API response to old-style for converter
      const {
        artboardsCount,
        previewUrl,
        files,
        hasMissingLinkedElements,
      } = res;

      const transformedFiles: any = {};

      Object.entries(files).forEach(([key, value]) => {
        transformedFiles[key] = {
          preview: value,
          full: value,
        };
      });

      formats.forEach(format => {
        if (transformedFiles[format]) return;

        transformedFiles[format] = {
          preview: null,
          full: null,
        };
      });

      const transformedResponse: IResponse = {
        artboardsCount,
        previewUrl,
        hostedPageId: "hosted-page-id-stub",
        hasMissingLinkedElements,
        ...transformedFiles,
      };

      return Promise.resolve(transformedResponse);
    })
    .catch(error => {
      Sentry.withScope(scope => {
        scope.setExtras({ fileName });
        scope.setTag("where", "Services: fetchFileDetails");
        Sentry.captureException(error);
      });
      throw error;
    });
};

export const fetchMultipleFileDetails = (
  fileNames: string[],
): Promise<IResponse> => {
  const parsedFilesList = fileNames.map(
    (name: string) => `fileName=${encodeURIComponent(name)}`,
  );
  const url = `${BACKEND_ENDPOINTS.multipleFileDetails}?${parsedFilesList.join(
    "&",
  )}`;

  return fetch(url, { method: "GET" })
    .then(handleHttpError)
    .then(r => r.json() as Promise<IResponse>)
    .then(handleError)
    .catch(error => {
      Sentry.withScope(scope => {
        scope.setExtras({ fileNames });
        scope.setTag("where", "Services: fetchFileDetails");
        Sentry.captureException(error);
      });
      throw error;
    });
};
