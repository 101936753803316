import React from "react";
import { useFela } from "react-fela";
import { IStyle } from "fela";
import useTranslation from "next-translate/useTranslation";

import { useTheme } from "@/hooks/useTheme";
import { Icon, StyledIconWrapper } from "@/components/Icon";
import { IWithCustomStyle } from "@/hooks/useTheme/types";
import { getFormattedFileSize } from "@/utils/getFormattedFileSize";
import { getFileExtension } from "@/utils/getFileExtension";
import { iconsMap } from "@/utils/iconMap";
import { fileTypesWithoutExtensions } from "@/services/fileDetailsService";
import { getConversionDetails } from "@/containers/Converter/common/helpers";
import { IExtension } from "../Converter/types";
import { IFileDetails } from "./types";

export const FileDetails: React.FC<IFileDetails & IWithCustomStyle> = ({
  name,
  size,
  conversionType,
  modifiedDate,
  onResetClick,
  handleDownloadButton,
  downloadUrl,
  customStyle = {},
  ...rest
}) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation();
  const download = downloadUrl
    ? `${process.env.NEXT_PUBLIC_XD2SKETCH_BACKEND_HOST}/download/${name}`
    : null;

  if (!name || !modifiedDate) return null;

  const formattedSize = size ? getFormattedFileSize(size) : "";
  const date = new Date(modifiedDate).toLocaleString();
  let extension = getFileExtension(name) as IExtension;

  if (!extension && conversionType) {
    const { conversionSource } = getConversionDetails(conversionType);

    if (conversionSource in fileTypesWithoutExtensions) {
      extension = fileTypesWithoutExtensions[conversionSource];
    }
  }

  const iconType =
    extension in iconsMap ? iconsMap[extension] : iconsMap[".fig"];

  const wrapperStyles: IStyle = {
    display: "flex",
    ...(download
      ? {
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          nested: {
            "> a": {
              margin: "10px 0 0",
            },
          },
          [bp.FROM_TABLET]: { flexDirection: "row" },
        }
      : {}),
    ...customStyle,
  };

  return (
    <div className={css(wrapperStyles)} {...rest}>
      <StyledIconWrapper wrapperStyle={{ flexShrink: 0 }}>
        <Icon iconType={iconType} />
      </StyledIconWrapper>

      <div
        className={css({
          margin: "0 10px 0 15px",
          flex: 1,
          minWidth: 0,
        })}
      >
        <p
          className={css({
            maxWidth: download ? "initial" : 195,
            minWidth: 0,
            flex: 1,
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontSize: 14,
            letterSpacing: 0.5,
            color: colors.DARK_GREY,
            [bp.ONLY_MOBILE]: {
              maxWidth: 200,
            },
            [bp.FROM_TABLET]: {
              maxWidth: download ? "initial" : 295,
            },
            [bp.FROM_DESKTOP]: {
              maxWidth: 105,
            },
            [bp.FROM_LARGE_DESKTOP]: {
              maxWidth: downloadUrl ? "initial" : 380,
            },
          })}
        >
          <strong
            className={css({
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            })}
          >
            {name}
          </strong>
        </p>

        <p
          className={css({
            fontSize: 12,
            letterSpacing: 0.5,
            color: colors.DARK_GREY,
          })}
        >
          {date}
          {!!size && ` · ${formattedSize}`}
        </p>
      </div>

      {download && (
        <a
          title="Download file"
          href={download}
          onClick={handleDownloadButton}
          className={css({
            height: 44,
            padding: "0 15px 0 10px",
            borderRadius: 8,
            border: `1px solid ${colors.ORANGE}`,
            background: colors.ORANGE,
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            textDecoration: "none",
            color: colors.WHITE,
            textTransform: "uppercase",
            fontSize: 14,
            fontWeight: 700,
          })}
        >
          <StyledIconWrapper
            wrapperStyle={{ height: 24, marginRight: 5 }}
            customStyle={{
              stroke: colors.WHITE,
            }}
          >
            <Icon iconType="ARROW-DOWN" />
          </StyledIconWrapper>
          {t("page/filename:download")}
        </a>
      )}

      {onResetClick && (
        <button
          type="button"
          onClick={onResetClick}
          className={css({
            margin: 0,
            padding: 0,
            border: "none",
            background: "none",
            marginLeft: "auto",
            cursor: "pointer",
          })}
        >
          <StyledIconWrapper customStyle={{ height: 12, width: 12 }}>
            <Icon iconType="CLOSE" />
          </StyledIconWrapper>
        </button>
      )}
    </div>
  );
};
