import React from "react";
import useTranslation from "next-translate/useTranslation";

import { useDropzoneContext } from "@/components/Dropzone/context";

import { DragAndDrop } from "./DragAndDrop";

// TODO - Create components for 'ActiveDrag' and 'InvalidFile' states
export const Content = () => {
  const { isDragActive, fileRejections } = useDropzoneContext();
  const { t } = useTranslation("converter");

  const isFileInvalid = !fileRejections || !!fileRejections[0];
  const isInitDrag = !isDragActive && !isFileInvalid;

  return (
    <>
      {isInitDrag && <DragAndDrop />}
      {isDragActive && <div>{t("selectingFile.dragNDrop.activeDrag")}</div>}
      {isFileInvalid && <div>{t("selectingFile.dragNDrop.invalidFile")}</div>}
    </>
  );
};
