import React from "react";
import { PreviewImage } from "@/components/FilePreview/PreviewImage";
import { FileDetails } from "@/containers/FileDetails";
import { useFela } from "react-fela";
import { IFile } from "@/services/fileDetailsService";
import { IStyle } from "fela";
import { IConversionType } from "@/containers/Converter/types";

interface IProps {
  previewUrl?: string;
  file?: File | IFile;
  conversionType?: IConversionType;
  onResetBtnClicked?: () => void;
  fileDetailsCustomStyle?: IStyle;
}

export const FilePreview: React.FC<IProps> = props => {
  const {
    previewUrl,
    file,
    conversionType,
    onResetBtnClicked,
    fileDetailsCustomStyle,
    children,
  } = props;

  const { css } = useFela();

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      })}
      data-testid="converter-step--preview"
    >
      <PreviewImage previewUrl={previewUrl} />

      {file && (
        <FileDetails
          name={file.name}
          size={file.size}
          conversionType={conversionType}
          modifiedDate={file.lastModified}
          onResetClick={onResetBtnClicked}
          customStyle={{
            marginLeft: 15,
            marginTop: 28,
            ...fileDetailsCustomStyle,
          }}
        />
      )}

      {children}
    </div>
  );
};
