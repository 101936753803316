import React from "react";
import { useFela } from "react-fela";
import Trans from "next-translate/Trans";

import { Icon } from "@/components/Icon";
import { useTheme } from "@/hooks/useTheme";

export const DragAndDrop = () => {
  const { css } = useFela();
  const { colors } = useTheme();

  return (
    <div style={{ textAlign: "center" }}>
      <Icon iconType="PLUS2" />

      <p
        className={css({
          marginTop: 30,
          color: colors.DARK_GREY,
          fontSize: 12,
          marginBottom: 4,
        })}
      >
        <Trans
          i18nKey="converter:selectingFile.dragNDrop.dropOrBrowse"
          components={[
            <span
              key={0}
              className={css({
                fontSize: 14,
                fontWeight: 600,
                display: "block",
              })}
            />,
            <span
              key={1}
              className={css({
                fontWeight: "bold",
                letterSpacing: 0.5,
                color: colors.RED,
                textDecoration: "none",
                transition: "opacity .15s",
                nested: {
                  ":hover": { opacity: 0.8 },
                },
              })}
            />,
          ]}
        />
      </p>
    </div>
  );
};
