import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { Heading } from "@/components/Heading";
import { FileDetails } from "@/containers/FileDetails";

interface IProps {
  file: File;
  onFileSubmit: () => void;
  onFileReset: () => void;
}

export const DroppedFile: React.FC<IProps> = props => {
  const { css } = useFela();
  const { t } = useTranslation("converter");

  const { file, onFileSubmit, onFileReset, children } = props;

  const handleFormSubmit: React.FormEventHandler = event => {
    event.preventDefault();

    onFileSubmit();
  };

  if (!file) {
    return null;
  }

  const { name, lastModified, size } = file;

  return (
    <form
      onSubmit={handleFormSubmit}
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
      })}
    >
      <Heading as="h4">{t("selectingFile.dragNDrop.title")}</Heading>

      <FileDetails
        name={name}
        size={size}
        modifiedDate={lastModified}
        onResetClick={onFileReset}
        customStyle={{ marginTop: 38 }}
      />

      {children}
    </form>
  );
};
