import { IExtension } from "@/containers/Converter/types";
import { IIconType } from "@/icons";

export const iconsMap: Record<IExtension, IIconType> = {
  ".xd": "ADOBEXD_FILE",
  ".sketch": "SKETCH_FILE",
  ".fig": "FIGMA_FILE",
  ".jam": "FIGJAM_FILE",
  ".psd": "PSD_FILE",
  ".psb": "PSD_FILE",
  ".pdf": "PDF_FILE",
  ".ai": "AI_FILE",
  ".eps": "EPS_FILE",
  ".googleslides": "GOOGLE-SLIDES-FILE-ICON",
  ".canva": "CANVA-FILE-ICON",
  ".indd": "INDESIGN-FILE-ICON",
  ".pptx": "POWERPOINT-FILE-ICON",
  ".miro": "MIRO-FILE-ICON",
  ".rtb": "MIRO-FILE-ICON",
  ".express": "EXPRESS-ICON",
  ".aep": "AFTER-EFFECTS-ICON",
  ".jpg": "JPG-ICON",
  ".freehand": "FREEHAND-ICON",
  ".key": "KEYNOTE",
  ".html": "HTML_ICON",
  ".wordpress": "WORDPRESS_ICON",
  ".vsdx": "VISIO_ICON",
};

export const logoIconsMap: Record<IExtension, IIconType> = {
  ".xd": "ADOBEXD",
  ".sketch": "SKETCH",
  ".fig": "FIGMA",
  ".jam": "FIGJAM",
  ".psd": "PHOTOSHOP",
  ".psb": "PHOTOSHOP",
  ".pdf": "PDF",
  ".ai": "AI",
  ".googleslides": "GOOGLE-SLIDES",
  ".canva": "CANVA",
  ".indd": "INDESIGN",
  ".pptx": "POWERPOINT",
  ".eps": "EPS_FILE",
  ".miro": "MIRO",
  ".rtb": "MIRO",
  ".express": "EXPRESS-ICON",
  ".aep": "AFTER-EFFECTS-ICON",
  ".jpg": "JPG-ICON",
  ".freehand": "FREEHAND-ICON",
  ".key": "KEYNOTE",
  ".html": "HTML_ICON",
  ".wordpress": "WORDPRESS_ICON",
  ".vsdx": "VISIO_ICON",
};
