import React from "react";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { IWithCustomStyle } from "@/hooks/useTheme/types";

type IProps = IWithCustomStyle & JSX.IntrinsicElements["div"];

export const Spinner: React.FC<IProps> = ({ customStyle = {} }) => {
  const { css, renderer } = useFela();
  const { colors } = useTheme();

  const keyframe = () => ({
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  });

  const animation = renderer.renderKeyframe(keyframe, {});

  return (
    <div
      data-testid="preloader"
      className={css(
        {
          fontSize: "10px",
          margin: "50px auto",
          textIndent: "-9999em",
          overflow: "hidden",
          width: 80,
          height: 80,
          borderRadius: "50%",
          background: `linear-gradient(to right, ${colors.WHITE} 10%, rgba(255, 255, 255, 0) 42%)`,
          position: "relative",
          animation: `${animation} 1.4s infinite linear`,
          transform: "translateZ(0)",
          nested: {
            ":before": {
              width: "50%",
              height: "50%",
              background: "#ad7ee5",
              borderRadius: "100% 0 0 0",
              position: "absolute",
              top: "0",
              left: "0",
              content: "''",
            },
            ":after": {
              background: colors.WHITE,
              width: "75%",
              height: "75%",
              borderRadius: "50%",
              content: "''",
              margin: "auto",
              position: "absolute",
              top: "0",
              left: "0",
              bottom: "0",
              right: "0",
            },
          },
        },
        customStyle,
      )}
    >
      Loading...
    </div>
  );
};
