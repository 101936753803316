import React from "react";
import { useFela } from "react-fela";
import { useTheme } from "../../hooks/useTheme";
import { StyledIconWrapper, Icon } from "../Icon";
import { IIconMessage } from "./types";

export const IconMessage: React.FC<IIconMessage> = ({ message }) => {
  const { css } = useFela();
  const { colors } = useTheme();

  return (
    <div
      className={css({
        height: 20,
        marginTop: 12,
        display: "flex",
        alignItems: "center",
      })}
    >
      <StyledIconWrapper width={20} height={20}>
        <Icon iconType="ERROR-ICON" />
      </StyledIconWrapper>

      <p
        className={css({
          fontSize: 14,
          color: colors.RED,
          fontWeight: "bold",
          letterSpacing: 0.5,
          marginLeft: 10,
        })}
      >
        {message}
      </p>
    </div>
  );
};
