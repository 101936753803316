import React from "react";
import useTranslation from "next-translate/useTranslation";

import { Heading } from "@/components/Heading";
import { Dropzone } from "@/components/Dropzone";

import { Content } from "./Content";

interface IProps {
  handleFileDrop: (files: File[]) => void;
  disabled?: boolean;
  acceptedFilesFormats: string[];
}

export const DragAndDropSelection: React.FC<IProps> = ({
  acceptedFilesFormats,
  handleFileDrop,
  children,
  disabled,
}) => {
  const { t } = useTranslation("converter");

  return (
    <>
      <Heading as="h4" customStyle={{ letterSpacing: 0.5 }}>
        {t("selectingFile.dragNDrop.title")}
      </Heading>
      <Dropzone
        disabled={disabled}
        onDrop={handleFileDrop}
        accept={acceptedFilesFormats}
      >
        <Content />
      </Dropzone>
      {children}
    </>
  );
};
