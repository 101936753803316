export const getFormattedFileSize = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const kb = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(kb));
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  return `${(bytes / kb ** i).toFixed(decimals)} ${sizes[i]}`;
};
