interface IError {
  statusCode: number;
  error: string;
  message: string;
}

const isError = <T>(response: T | IError): response is IError => {
  const error = response as IError;
  return !!error.error && !!error.message && !!error.statusCode;
};

export const handleHttpError = (response: Response) => {
  if (response.status >= 400) {
    throw new Error(response.statusText);
  }
  return response;
};

export const handleError = <T>(response: T | IError) => {
  if (!isError(response)) {
    return response;
  }
  throw new Error(response.message);
};
